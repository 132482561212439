.projects {
  &__section {
    padding: 5rem;
  }

  &__page-container {
    margin-top: 5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;

    background: linear-gradient(
      105deg,
      #f7f7f7 39%,
      $primary-text-color-dark 39%
    );
  }

  &__illustration {
    width: 100%;
    &-box {
      width: 38%;
    }
  }

  &__desktop__container {
    height: 50%;
    width: 50%;
  }

  &__card {
    &--desktop {
      position: relative;
      height: 50rem;
      width: 80%;
      max-width: 40rem;
      margin: 0 auto;
      background: $text-white;
    }

    &__buttons-container {
      position: absolute;
      bottom: 1rem;
      width: 100%;
      display: flex;
      padding: 1rem 5rem;
      justify-content: space-between;

      & button {
        color: $primary-text-color-dark;
      }
    }
  }

  &-slider {
    width: 65rem;
    height: 40rem;
    margin: 0 auto;
    display: flex;
    overflow: hidden;
    position: relative;

    &__container {
      width: 100%;
      display: flex;
      height: 40rem;
      position: absolute;
      transform: translate(0);
      transition: transform 0.8s;

      & img {
        width: 100%;
      }
    }

    &__controls {
      color: #fff;
      font-size: 3rem;

      .previous {
        left: 20px;
        content: "\f0d9";

        &:hover {
          left: 10px;
        }
      }

      .next {
        right: 30px;
        content: "\f0da";

        &:hover {
          right: 20px;
        }
      }

      .previous,
      .next {
        width: 20px;
        display: inline-block;
        cursor: pointer;
        opacity: 0.75;
        transform: translateY(-50%);
        top: 50%;
        position: absolute;
        transition: all 0.2s ease;
      }
    }
  }

  &__item-container {
    width: 100%;
  }
}

.shortcuts {
  position: absolute;
  bottom: 20px;
  width: 100%;
  height: 20px;
  display: flex;
  justify-content: center;

  .shortcut {
    cursor: pointer;
    background-color: rgba($primary-text-color-dark, 0.7);
    border: 1px groove $text-white;
    width: 20px;
    margin: 0 5px;
    border-radius: 50%;
    transition: all 0.3s ease;

    &:hover {
      background-color: rgba($primary-text-color-dark, 1);
    }

    &.active {
      background-color: rgba($primary-text-color-dark, 1);
    }
  }
}

@media screen and (max-width: 1068px) {
  .projects {
    &__card {
      &--desktop {
        width: 100%;
        height: 45rem;
      }
    }
  }
}

@media screen and (max-width: 860px) {
  .projects {
    &__card {
      &--desktop {
        width: 100%;
        height: 40rem;
      }

      &__buttons-container {
        padding: 1rem 2.5rem;
      }
    }
  }
}
