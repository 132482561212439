.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 3rem;

  &__details {
    font-size: 1.4rem;
    color: $primary-text-color-dark;
    text-align: center;

    & a {
      padding-bottom: 0.2rem;
      border-bottom: 1px solid $primary-text-color-light;
      text-decoration: none;
      color: inherit;

      &:hover,
      &:visited,
      &:active {
        border-color: $primary-text-color-dark;
      }
    }
  }

  &__contacts {
    display: flex;
    justify-content: flex-end;
    flex: 1;
    align-items: center;
    margin-left: 3rem;

    & * {
      height: 3.5rem;
      margin: 0.5rem;
      transition: transform 0.3s;

      &:hover {
        transform: scale(1.1);
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .footer {
    justify-content: space-between;
    flex-direction: column-reverse;

    &__contacts {
      margin: 3rem 0;
    }
  }
}
