.about {
  &__section {
    padding: 5rem 0rem;
  }

  &__container {
    margin-top: 5rem;
    display: flex;
    justify-content: space-evenly;
    padding: 2rem;
    align-items: center;
    background: linear-gradient(92deg, #fafafa 40%, transparent 50%);
  }

  &__illustration {
    &-box {
      width: 50%;
      max-width: 70rem;
    }
    width: 90%;
  }

  &__info {
    &-container {
      width: 40%;
      font-size: 2rem;
      color: $secondary-text-color;
      padding-left: 5rem;
      letter-spacing: 0.2rem;
    }

    &-item {
      padding: 0.5rem;
      margin: 0.2rem;
    }
  }
}

.mentor {
  &__logo {
    width: 100%;

    &-box {
      width: 12.5rem;
      border-radius: 50%;
      overflow: hidden;
      border: 0.4rem solid $primary-text-color-dark;
      animation: heart-beat 2s ease-in infinite forwards;
      box-shadow: 0 12px 1rem -4px #0000005e;
    }
  }

  &__contacts-container {
    display: flex;
    align-content: center;
    width: 100%;
    justify-content: center;

    & a {
      margin: 1rem;
    }

    & img {
      height: 3.5rem;
    }
  }

  &__info {
    text-align: center;
    font-size: 1.6rem;
    color: $secondary-text-color;
    margin: 2rem;

    p {
      padding: 0.2rem;
      letter-spacing: 0.2rem;
      font-size: 1.8rem;
      margin: 0.3rem 0;
    }

    &-container {
      margin: 4rem;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }
}

@media screen and (max-width: 968px) {
  .about {
    &__section {
      padding-bottom: 7rem;
    }

    &__container {
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      background: transparent;
    }

    &__illustration {
      &-box {
        width: 95%;
        background: #fafafa;
        padding: 2rem;
        text-align: center;
      }
      width: 75%;
    }

    &__info {
      &-container {
        width: 95%;
        padding: 0;
        margin-top: 5rem;
        font-size: 1.6rem;
        text-align: center;
      }
    }
  }

  .mentor {
    &__info {
      &-container {
        flex-direction: column;
        margin: 4rem 0;
      }
    }
  }
}
