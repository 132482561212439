.resume {
  &__container {
    display: flex;
    margin-top: 10rem;
    justify-content: space-between;
    background: linear-gradient(
      115deg,
      #f1f1f1 50%,
      $primary-text-color-dark 50%
    );
    padding: 2rem;
  }

  &__illustration-box {
    width: 40%;
  }

  &__page-container {
    flex: 1;
  }
}

@media screen and (max-width: 1201px) {
}

@media screen and (max-width: 901px) {
  .resume {
    &__container {
      // background: linear-gradient(180deg, #f1f1f1 50%, transparent 50%);
      background: transparent;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &__page {
      height: 80rem;
    }

    &__illustration {
      width: 60%;
      background: red;

      &-box {
        background: #f1f1f1;
        width: 100%;
      }
    }
  }
}
