.heading {
  color: $primary-text-color-dark;

  &__primary {
    font-size: 4rem;
  }

  &__ternary {
    font-size: 1.8rem;
  }
}
