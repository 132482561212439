.mt-1 {
  margin-top: 1rem !important;
}

.mt-2 {
  margin-top: 2rem !important;
}

.mt-3 {
  margin-top: 3rem !important;
}

.mt-4 {
  margin-top: 4rem !important;
}

.mt-5 {
  margin-top: 5rem !important;
}

.mb-1 {
  margin-bottom: 1rem !important;
}

.mb-2 {
  margin-bottom: 2rem !important;
}

.mb-3 {
  margin-bottom: 3rem !important;
}

.mb-4 {
  margin-bottom: 4rem !important;
}

.mb-5 {
  margin-bottom: 5rem !important;
}

.m-1 {
  margin: 1rem !important;
}
.m-2 {
  margin: 2rem !important;
}
.m-3 {
  margin: 3rem !important;
}
.m-4 {
  margin: 4rem !important;
}
.m-5 {
  margin: 5rem !important;
}

.pt-1 {
  padding-top: 1rem !important;
}

.pt-2 {
  padding-top: 2rem !important;
}

.pt-3 {
  padding-top: 3rem !important;
}

.pt-4 {
  padding-top: 4rem !important;
}

.pt-5 {
  padding-top: 5rem !important;
}

.pb-1 {
  padding-bottom: 1rem !important;
}

.pb-2 {
  padding-bottom: 2rem !important;
}

.pb-3 {
  padding-bottom: 3rem !important;
}

.pb-4 {
  padding-bottom: 4rem !important;
}

.pb-5 {
  padding-bottom: 5rem !important;
}

.p-1 {
  padding: 1rem !important;
}
.p-2 {
  padding: 2rem !important;
}
.p-3 {
  padding: 3rem !important;
}
.p-4 {
  padding: 4rem !important;
}
.p-5 {
  padding: 5rem !important;
}

.lighter {
  font-weight: 200 !important;
}

.light {
  font-weight: 400 !important;
}

.bold {
  font-weight: 700 !important;
}

.bolder {
  font-weight: 900 !important;
}

.align-center {
  text-align: center !important;
}

.shadow-down {
  box-shadow: 0 0.4rem 0.5rem 1px rgba($shadow-color, 0.7) !important;
}

.flex {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.m-auto {
  margin: 0 auto !important;
}

.beat {
  animation: beat 1s cubic-bezier(1, 0.36, 0, 1.51) infinite forwards;
}

.fade-in__scale-animation {
  animation: scale-down-normal 0.5s ease-in-out, fade-in 0.5s ease-in-out;
}
