.header {
  &__desktop__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__logo-box {
    height: 5.5rem;
  }

  &__logo {
    height: 100%;
  }

  &__nav {
    &-items__container {
      display: flex;
      padding: 1.4rem;
      list-style: none;
      align-items: center;
    }

    &-item {
      margin: 1rem;
      font-size: 1.6rem;
      color: $primary-text-color-dark;

      & a {
        text-decoration: none;
        display: inline-block;
        color: inherit;
        padding: 0.5rem 1rem;
        border-radius: 2.6rem;

        &:hover {
          font-weight: bolder;
        }
      }
    }
  }
}

.hamberger-box {
  top: 0rem;
  width: 3rem;
  right: 2rem;
  height: 3rem;
  display: none;
  cursor: pointer;
  // position: relative;
}

.hamberger {
  top: 6rem;
  right: 5rem;
  width: 3rem;
  height: 0.4rem;
  cursor: pointer;
  position: absolute;
  border-radius: 0.3rem;
  display: inline-block;
  transform: translateY(-50%);
  background: $primary-text-color-dark;
  z-index: 10;

  &::after,
  &::before {
    content: "";
    width: 80%;
    height: 100%;
    position: absolute;
    background: inherit;
    border-radius: inherit;
    z-index: 10;
    opacity: 1;
  }

  &::before {
    left: 0;
    top: -0.8rem;
  }
  &::after {
    right: 0;
    top: 0.8rem;
  }
}

.active {
  background: $primary-text-color-dark;
  font-weight: bolder;
  color: #fff !important;
}

@media screen and (max-width: 641px) {
  .header {
    &__nav {
      &-items__container {
        display: none;
      }
    }
  }

  .hamberger-box {
    display: block;
  }
}
