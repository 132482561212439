.skills {
  &-section__container {
    padding: 5rem 0;
  }

  &__illustrator {
    height: 100%;

    &-box {
      height: 40rem;
      margin-top: 5rem;
      text-align: center;
    }
  }

  &__card {
    width: 24rem;
    margin: 0.7rem;
    max-width: 80vw;
    overflow: hidden;
    position: relative;
    padding-bottom: 4rem;
    border-radius: 0.8rem;
    background: #f7f7f7;
    transition: transform 0.5s;
    backface-visibility: hidden;

    &__img-box {
      height: 50%;
      padding: 2rem;
      position: relative;
      text-align: center;
      padding-bottom: 5rem;
      background: lighten($text-white, 5);
      clip-path: circle(70% at center 0);
      border: 4px solid rgba($primary-text-color-dark, 0.7);
      & img {
        width: 50%;
      }
    }

    &:hover {
      z-index: 3;
      transform: scale(1.05);
      animation: heart-beat 1s ease-in infinite forwards;
    }

    &--info {
      font-size: 1.4rem;
      margin-top: 1rem;

      &-box {
        padding: 1rem;
        text-align: center;
      }
    }
  }
}

.more__info {
  left: 50%;
  bottom: 2.8rem;
  font-size: 1.3rem;
  color: $text-white;
  position: absolute;
  padding: 0.8rem 1.4rem;
  border-radius: 10rem;
  text-decoration: none;
  display: inline-block;
  transform: translateX(-50%);
  background-color: $primary-text-color-dark;
  box-shadow: 0 0.4rem 0.2rem 1px rgba($primary-text-color-dark, 0.7);

  &:active {
    transform: translateY(3px) translateX(-50%);
    box-shadow: 0 0.15rem 0.2rem 1px rgba($primary-text-color-dark, 0.7);
  }
}

@media screen and (max-width: 820px) {
  .skills {
    &-section__container {
      padding-bottom: 10rem;
    }

    &__illustrator {
      &-box {
        height: 25rem;
      }
    }

    &__card {
      &__img-box {
        padding-bottom: 8rem;
      }
    }
  }
}

.flex__wrap {
  display: flex;
  margin: 0 auto;
  flex-wrap: wrap;
  max-width: 121rem;
  justify-content: center;
}
