@keyframes bounce {
  20%,
  50%,
  80% {
    transform: scaleY(-0.5rem);
  }

  35%,
  70%,
  100% {
    transform: none;
  }
}

@keyframes heart-beat {
  20% {
    transform: scale(1.02);
  }

  50% {
    transform: scale(1.05);
  }

  0%,
  40%,
  100% {
    transform: none;
  }
}

@keyframes beat {
  50% {
    transform: scale(1.1);
  }

  0%,
  100% {
    transform: none;
  }
}

// @keyframes updown {
//   0%,
//   100% {
//     transform: none;
//   }
//   10%,
//   30%,
//   50%,
//   70% {
//     transform: translateY(-1rem);
//   }

//   20%,
//   40%,
//   60%,
//   80% {
//     transform: translateY(1rem);
//   }
// }

@keyframes updown {
  0%,
  100% {
    transform: none;
  }
  40% {
    transform: translateY(-1rem);
  }

  80% {
    transform: translateY(1rem);
  }
}

@keyframes slide-in-left {
  0% {
    opacity: 0.6;
    transform: translateX(-10rem);
  }

  100% {
    transform: none;
    opacity: 1;
  }
}

@keyframes slide-in-right {
  0% {
    opacity: 0.6;
    transform: translateX(10rem);
  }

  100% {
    transform: none;
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes scale-down-normal {
  0% {
    transform: scale(0.6);
  }

  100% {
    transform: none;
  }
}
