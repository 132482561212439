.sidebar {
  &__container {
    background: $primary-text-color-dark;
    position: fixed;
    top: 0;
    left: 0;
    width: 0;
    max-width: 0;
    height: 100vh;
    z-index: 30;
    overflow: hidden;
    backface-visibility: hidden;
    transition: all 0.5s;
  }

  &__logo {
    &-box {
      height: 5rem;
      text-align: center;
      margin-top: 2rem;
    }

    height: 100%;
  }

  &__items--container {
    list-style: none;
    position: absolute;
    top: 40%;
    left: 50%;
    width: 100%;
    text-align: center;
    transform: translate(-50%, -50%);
  }

  &__item {
    margin: 2.5rem;

    & a {
      text-decoration: none;
      color: $text-white;
      font-size: 2rem;
      padding: 1rem 1.8rem;
      border-radius: 50%;

      &:hover {
        font-weight: bolder;
      }

      &.active {
        font-weight: bolder;
        color: $primary-text-color-dark !important;
        background: $text-white;
      }
    }
  }
}

.show__sidebar {
  max-width: 70vw;
  width: 40rem;
}
