.btn {
  padding: 1rem 1.8rem;
  border: none;
  background: transparent;
  transition: all 0.1s;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:hover {
    transform: scale(1.02);
  }

  &__primary {
    background: $primary-text-color-dark;
    border-radius: 5rem;
    color: $text-white;
    box-shadow: 0 0.6rem 0.2rem 0 darken($primary-text-color-dark, 20);

    &:active {
      box-shadow: 0 0.4rem 0.2rem 0 darken($primary-text-color-dark, 20);
      transform: translateY(0.2rem);
    }

    &--white {
      background: rgba($text-white, 0.95);
      box-shadow: 0 0.6rem 0.2rem 0 darken($text-white, 10);
      color: $primary-text-color-dark;

      &:active {
        box-shadow: 0 0.4rem 0.2rem 0 darken($text-white, 20);
      }

      &:hover {
        background-color: rgba($text-white, 1);
        color: $primary-text-color-light;
      }
    }
  }

  &__secondary {
    border: 1px solid $primary-text-color-dark;
    color: $primary-text-color-dark;

    &:hover {
      box-shadow: 0 0.4rem 0.5rem 1px rgba($shadow-color, 0.4);
      border-color: $primary-text-color-light;
    }
  }
}
