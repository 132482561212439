.home {
  &__section {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 2rem 0;
  }

  &__illustration--box {
    height: 100%;
    width: 45%;
    max-width: 70rem;
    & img {
      margin-top: 5rem;
      height: 100%;
      width: 100%;
    }
  }
}

.main-heading {
  color: $secondary-text-color;
  font-size: 3.2rem;
  letter-spacing: 0.55rem;
  text-align: center;
  backface-visibility: hidden;

  span {
    display: inline-block;
    font-size: 2.5rem;
    animation: wave 0.7s ease-in-out infinite;
    animation-fill-mode: both;
  }
}

@keyframes wave {
  0%,
  100% {
    transform: rotate(25deg);
  }
  50% {
    transform: rotate(55deg);
  }
}

.sub-heading--main {
  font-size: 5.5rem;
  text-align: center;
  text-align: center;
  color: $primary-text-color-dark;
  text-shadow: 0.2rem 0.2rem 4px $secondary-text-color;

  & span {
    display: inline-block;
    &:nth-child(1) {
      animation: slide-in-left 0.5s ease-in-out;
    }
    &:nth-child(2) {
      animation: slide-in-right 0.5s ease-in-out;
    }
  }
}

.sub-heading--sub {
  font-size: 1.5rem;
  text-align: center;
  color: $secondary-text-color;
  letter-spacing: 0.2rem;
}

.cti__buttons-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .home {
    &__section {
      flex-direction: column;
    }

    &__illustration--box {
      width: 80%;
      & img {
        margin-top: 5rem;
        height: 100%;
        width: 100%;
      }
    }
  }
}
