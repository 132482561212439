.services {
  &__page-container {
    padding: 5rem 0;
  }
  &__container {
    display: flex;
    width: 118rem;
    max-width: 100%;
    margin: 0 auto;
    justify-content: center;
    flex-wrap: wrap;
  }

  &__card {
    // height: 45rem;
    width: 32rem;
    // background: #e1e1e1;
    margin: 2rem 1rem;
    padding: 1rem;
    box-shadow: 0 0rem 1rem -0.5rem rgba($primary-text-color-dark, 0.5),
      0 1rem 1.2rem -1rem rgba($shadow-color, 0.5),
      0 2.5rem 1.5rem -3rem rgba($primary-text-color-dark, 0.5);

    &:hover {
      animation: updown 0.4s linear forwards;
    }

    &-logo {
      width: 100%;
      height: 20rem;
      &-box {
        width: 100%;
      }
    }

    &-title {
      font-size: 2rem;
      margin: 1rem;
      color: $secondary-text-color;
    }
  }
}
