.contact {
  &__illustration-box {
    width: 80%;
    padding-right: 2rem;

    & img {
      width: 100%;
    }
  }

  &__form {
    &__container {
      display: flex;
      padding: 3rem 0;
      justify-content: space-between;
      align-items: center;
      background: linear-gradient(
        105deg,
        $primary-text-color-dark 52.5%,
        rgba(#e1e1e1, 0.15) 50%
      );
    }

    max-width: 110rem;
    width: 100%;
    justify-self: self-start;
  }
}

.form {
  &__group {
    margin: 2.8rem 2rem;
    position: relative;
  }

  &__label {
    position: absolute;
    top: 1.2rem;
    left: 1rem;
    letter-spacing: 0.07rem;
    font-size: 1.2rem;
    transition: all 0.4s;
    color: $primary-text-color-dark;
    opacity: 0;
    font-size: 1.35rem;
  }

  &__input,
  &__textarea {
    width: 90%;
    display: block;
    font: inherit;
    font-size: 1.6rem;
    padding: 1rem;
    border-radius: 0.2rem;
    background: $text-white;
    color: $primary-text-color-dark;
    border: 1px solid $primary-text-color-dark;
    border-bottom: 0.4rem solid $primary-text-color-dark;

    &::placeholder {
      color: inherit;
    }

    &:focus {
      outline: none;
      box-shadow: 0 3px 0.4rem 1px rgba($shadow-color, 0.4);
    }

    &:focus:not(:placeholder-shown):invalid {
      border-bottom-color: tomato;
    }

    &:not(:focus):invalid {
      border-bottom-color: tomato;
    }

    &:not(:focus):valid {
      border-bottom-color: green;
    }
  }

  &__input:not(:placeholder-shown) + &__label {
    color: $text-white;
    opacity: 1;
    top: -2rem;
  }

  &__textarea {
    height: 15rem !important;
    resize: none;
  }
}

.new_mail {
  height: 3rem;
}

@media screen and (max-width: 768px) {
  .contact {
    &__section {
      padding-bottom: 0;
    }
    &__form {
      padding-top: 5rem;
      margin-top: 5rem;
      justify-self: center;
      background: $primary-text-color-dark;

      .form__group {
        margin: 2.8rem 5rem;
      }

      &__container {
        background: linear-gradient(
          355deg,
          $primary-text-color-dark 40%,
          rgba(#e1e1e1, 0.15) 40%
        );
        padding: 0;
        flex-direction: column-reverse;
      }
    }

    &__illustration-box {
      width: 80%;
      & img {
        margin-top: 5rem;
        height: 100%;
        width: 100%;
      }
    }
  }

  .form__input,
  .form__textarea {
    width: 100%;
  }
}

@media screen and (max-width: 520px) {
  .contact__form .form__group {
    margin: 2.8rem 2rem;
  }
}
