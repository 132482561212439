*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  font-size: 62.5%;
}

body {
  font: inherit;
  font-family: "Roboto", sans-serif;
  position: relative;
  box-sizing: inherit;
  padding: 2.5rem 7.5rem;
}

@media screen and (max-width: 768px) {
  :root {
    font-size: 50%;
  }

  body {
    padding: 2.5rem 4rem;
  }
}

::selection {
  color: $text-white;
  background: $primary-text-color-dark;
}
