.tags__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  margin: 2rem;
}

.tag {
  padding: 0.4rem 1.4rem;
  margin: 1rem;
  font-size: 1.4rem;
  background: $primary-text-color-dark;
  color: $text-white;
  border-radius: 15rem;
}
